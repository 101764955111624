@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Matrix rain animation */
@keyframes matrix-rain {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100vh);
  }
}

.animate-matrix-rain {
  animation: matrix-rain 10s linear infinite;
}

/* Mode transition */
.mode-transition {
  transition: all 0.5s ease-out;
}

/* Glow effects */
.glow-text {
  text-shadow: 0 0 10px currentColor;
}

.glow-border {
  box-shadow: 0 0 10px currentColor;
}

/* Custom scrollbar utilities */
@layer utilities {
  /* Hide scrollbar for mobile */
  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Show scrollbar for desktop */
  @media (min-width: 768px) {
    .show-scrollbar {
      scrollbar-width: thin;
      -ms-overflow-style: auto;
    }
    .show-scrollbar::-webkit-scrollbar {
      display: block;
      height: 6px;
    }
    .show-scrollbar::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;
    }
    .show-scrollbar::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 3px;
    }
    .show-scrollbar::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
}